(function () {
  'use strict';

  function _slicedToArray(arr, i) {
    return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
  }

  function _toConsumableArray(arr) {
    return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
  }

  function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr)) return _arrayLikeToArray(arr);
  }

  function _arrayWithHoles(arr) {
    if (Array.isArray(arr)) return arr;
  }

  function _iterableToArray(iter) {
    if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter);
  }

  function _iterableToArrayLimit(arr, i) {
    if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = undefined;

    try {
      for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
        _arr.push(_s.value);

        if (i && _arr.length === i) break;
      }
    } catch (err) {
      _d = true;
      _e = err;
    } finally {
      try {
        if (!_n && _i["return"] != null) _i["return"]();
      } finally {
        if (_d) throw _e;
      }
    }

    return _arr;
  }

  function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(o);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
  }

  function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;

    for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];

    return arr2;
  }

  function _nonIterableSpread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  function _nonIterableRest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  function _createForOfIteratorHelper(o, allowArrayLike) {
    var it;

    if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) {
      if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
        if (it) o = it;
        var i = 0;

        var F = function () {};

        return {
          s: F,
          n: function () {
            if (i >= o.length) return {
              done: true
            };
            return {
              done: false,
              value: o[i++]
            };
          },
          e: function (e) {
            throw e;
          },
          f: F
        };
      }

      throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
    }

    var normalCompletion = true,
        didErr = false,
        err;
    return {
      s: function () {
        it = o[Symbol.iterator]();
      },
      n: function () {
        var step = it.next();
        normalCompletion = step.done;
        return step;
      },
      e: function (e) {
        didErr = true;
        err = e;
      },
      f: function () {
        try {
          if (!normalCompletion && it.return != null) it.return();
        } finally {
          if (didErr) throw err;
        }
      }
    };
  }

  function orderReceiptDesktop (transactionID, isFRCanada, totalRevenue, shipping, tax, products, custStatus) {
    // US and other cultures
    var transactionWrapper = $('.detail-wrapper')[2];
    transactionID = $(transactionWrapper).find('.detail-data').text().trim();
    var totalWrapper = $('.additional-fees-row.grand-total').find('.column.fee');
    totalWrapper = totalWrapper.text().trim();
    var shippingWrapper = $('.additional-fees-row.top').find('.column.fee');
    shippingWrapper = shippingWrapper.text().trim();
    var taxWrapper = $('.additional-fees-row.middle').find('.column.fee');
    taxWrapper = taxWrapper.text().trim();

    if (isFRCanada) {
      totalWrapper = totalWrapper.split(',').join('.');
      shippingWrapper = shippingWrapper.split(',').join('.');
      taxWrapper = taxWrapper.split(',').join('.');
    }

    totalRevenue = currency(totalWrapper);
    shipping = currency(shippingWrapper);
    tax = currency(taxWrapper);
    $('.order-products-table').find('.product-table-data-row').each(function () {
      var items = {};
      var sku = $(this).find('.item').text().trim().split(' -')[0];

      if (sku) {
        items[sku] = {};
        items[sku]['id'] = sku;
        $.ajax({
          url: "/externalservices/api/products?skus=".concat(sku, "&culture=").concat(PageClientData.UICulture),
          type: 'GET',
          async: false,
          dataType: 'json',
          success: function success(data) {
            $.each(data, function () {
              items[sku]['category'] = data[0].MobileContent.DefaultCategoryName; //Default Category inside MobileContent from API call;
            });
          }
        });
        items[sku]['name'] = $(this).find('span.dark-text').text().trim();
        items[sku]['quantity'] = parseInt($(this).find('.qty').text().trim());
        var price = 0;
        var metric1 = 0;

        if ($(this).find('.reg-price').text().trim() == '-' || $(this).find('.price').text().trim() == '-') {
          price = 0;
          metric1 = $(this).find('.points').text().trim();
        } else if (custStatus == 'preferred' || custStatus == 'vip') {
          price = $(this).find('.price').text().trim();

          if (isFRCanada) {
            price = price.split(',').join('.');
          }

          price = currency(price).value / items[sku]['quantity'];
        } else {
          price = $(this).find('.reg-price').text().trim();

          if (isFRCanada) {
            price = price.split(',').join('.');
          }

          price = currency(price).value / items[sku]['quantity'];
        }

        items[sku]['price'] = price.toString();
        items[sku]['brand'] = '';
        items[sku]['variant'] = ''; //items[sku]['quantity'] = parseInt($(this).find('.qty').text().trim());

        items[sku]['coupon'] = '';
        items[sku]['metric1'] = metric1; // If points were used to purchase this item, put that here

        products.push(items[sku]);
        console.log(JSON.parse(sessionStorage.getItem('mela-cart')));
        console.log('before removing session object');
        sessionStorage.removeItem('mela-cart'); //clear mela-cart object in sessionstorage on orderreceipt page
      }
    });
    console.log(transactionID, totalRevenue, shipping, tax, products);
    return [transactionID, totalRevenue, shipping, tax, products];
  }

  function orderReceiptMobile (transactionID, isFRCanada, totalRevenue, shipping, tax, products, custStatus) {
    // US and other cultures
    transactionID = $('h1.heading + ul.content__list > li:last-child > span:last-child').text();
    var totalWrapper = $('.GrandTotalPrice').text().trim();
    var shippingWrapper = $('#OrderHistoryTotals > div:nth-child(4) > div.dark-text').text().trim();
    var taxWrapper = $('#OrderHistoryTotals > div:nth-child(7) > div.dark-text').text().trim();

    if (isFRCanada) {
      totalWrapper = totalWrapper.split(',').join('.');
      shippingWrapper = shippingWrapper.split(',').join('.');
      taxWrapper = taxWrapper.split(',').join('.');
    }

    totalRevenue = currency(totalWrapper);
    shipping = currency(shippingWrapper);
    tax = currency(taxWrapper);
    $('.productsOrdered').find('.product-wrapper').each(function () {
      var items = {};
      var sku = $(this).find('.product-size-sku > span').text().trim().split(': ')[1];

      if (sku) {
        items[sku] = {};
        items[sku]['id'] = sku;
        $.ajax({
          url: "/externalservices/api/products?skus=".concat(sku, "&culture=").concat(PageClientData.UICulture),
          type: 'GET',
          async: false,
          dataType: 'json',
          success: function success(data) {
            $.each(data, function () {
              items[sku]['category'] = data[0].MobileContent.DefaultCategoryName; //Default Category inside MobileContent from API call;
            });
          }
        });
        console.log('mobile ' + items[sku]);
        items[sku]['name'] = $(this).find('.product-information > h2 > span').text().trim();
        items[sku]['quantity'] = parseInt($(this).find('.OrderHistoryQty').text().trim());
        var price = 0;
        var metric1 = 0;

        if ($(this).find('.regular-price').text().trim() == '-' || $(this).find('.customer-price').text().trim() == '-') {
          price = 0;
          metric1 = $(this).find('.price-points > span:nth-child(3)').text().trim().match(/\d+/g)[0];
        } else if (custStatus == 'preferred' || custStatus == 'vip') {
          price = $(this).find('.customer-price').text().split('$')[1].trim();

          if (isFRCanada) {
            price = price.split(',').join('.');
          }

          price = currency(price).value / items[sku]['quantity'];
        } else {
          price = $(this).find('.regular-price > span:first-child').text().split('$')[1].trim();

          if (isFRCanada) {
            price = price.split(',').join('.');
          }

          price = currency(price).value / items[sku]['quantity'];
        }

        items[sku]['price'] = price;
        items[sku]['brand'] = '';
        items[sku]['variant'] = '';
        items[sku]['coupon'] = '';
        items[sku]['metric1'] = metric1; // If points were used to purchase this item, put that here

        products.push(items[sku]);
        sessionStorage.removeItem('mela-cart'); //clear mela-cart object in sessionstorage on order receipt page
      }
    });
    return [transactionID, totalRevenue, shipping, tax, products];
  }

  /*******************************
   * GTM Cart/Checkout Functions
   *******************************/

  /**** window.load ****/

  $(window).on('load', function () {
    /*******************************
     * Supplemental Functions
     *******************************/
    // melaVideoPlayer overriding
    setTimeout(function () {
      try {
        var originalSetup = MelaVideoPlayer.prototype.SetupVideo;

        MelaVideoPlayer.prototype.SetupVideo = function (videoSetup) {
          // console.log(videoSetup);
          var originalOnReadyFunction = videoSetup.OnReadyFunction;
          var originalOnFirstLaunchFunction = videoSetup.OnFirstLaunchFunction;
          var originalOnPlayFunction = videoSetup.OnPlayFunction;
          var originalOnPauseFunction = videoSetup.OnPauseFunction;
          var originalOnCompleteFunction = videoSetup.OnCompleteFunction;
          var originalOnPlayProgressFunction = videoSetup.OnPlayProgressFunction;
          var allowCall = true;
          var total, quarter_duration, // 25%
          half_duration, // 50%
          threeQuarter_duration; // 75%
          // console.log(videoSetup.Video.MediaItemName);
          // console.log(videoSetup);
          // console.log(videoSetup.OnReadyFunction);

          if (!/trackAction\(/i.test(videoSetup.OnReadyFunction)) {
            videoSetup.OnReadyFunction = function (event) {
              // console.log('onReadyFunction called');
              trackAction({
                id: 22,
                eL: videoSetup.Video.MediaItemName
              });
              if (originalOnReadyFunction) originalOnReadyFunction(event);
            };
          }

          if (!/trackAction\(/i.test(videoSetup.OnFirstLaunchFunction)) {
            videoSetup.OnFirstLaunchFunction = function (event) {
              // console.log('onFirstLaunchFunction called');
              total = Math.floor(event.duration);
              quarter_duration = Math.floor(total * 0.25);
              half_duration = Math.floor(total * 0.5);
              threeQuarter_duration = Math.floor(total * 0.75);
              trackAction({
                id: 15,
                eL: videoSetup.Video.MediaItemName
              });
              if (originalOnFirstLaunchFunction) originalOnFirstLaunchFunction(event);
            };
          }

          if (!/trackAction\(/i.test(videoSetup.OnPlayFunction)) {
            videoSetup.OnPlayFunction = function (event) {
              // console.log('play');
              trackAction({
                id: 15,
                eL: videoSetup.Video.MediaItemName
              });
              if (originalOnPlayFunction) originalOnPlayFunction(event);
            };
          }

          if (!/trackAction\(/i.test(videoSetup.OnPauseFunction)) {
            videoSetup.OnPauseFunction = function (event) {
              // console.log('pause');
              trackAction({
                id: 16,
                eL: videoSetup.Video.MediaItemName
              });
              if (originalOnPauseFunction) originalOnPauseFunction(event);
            };
          }

          if (!/trackAction\(/i.test(videoSetup.OnCompleteFunction)) {
            videoSetup.OnCompleteFunction = function (event) {
              trackAction({
                id: 17,
                eL: videoSetup.Video.MediaItemName
              });
              if (originalOnCompleteFunction) originalOnCompleteFunction(event);
            };
          }

          if (!/trackAction\(/i.test(videoSetup.OnPlayProgressFunction)) {
            videoSetup.OnPlayProgressFunction = function (event) {
              var pos = Math.floor(event.position);

              if (pos == quarter_duration) {
                if (allowCall) {
                  allowCall = false;
                  trackAction({
                    id: 19,
                    eL: videoSetup.Video.MediaItemName
                  });
                  setTimeout(resetAllowCall, 5000);
                }
              }

              if (pos == half_duration) {
                if (allowCall) {
                  allowCall = false;
                  trackAction({
                    id: 20,
                    eL: videoSetup.Video.MediaItemName
                  });
                  setTimeout(resetAllowCall, 5000);
                }
              }

              if (pos == threeQuarter_duration) {
                if (allowCall) {
                  allowCall = false;
                  trackAction({
                    id: 21,
                    eL: videoSetup.Video.MediaItemName
                  });
                  setTimeout(resetAllowCall, 5000);
                }
              }
            };

            if (originalOnPlayProgressFunction) originalOnPlayProgressFunction(event);
          }

          function resetAllowCall() {
            allowCall = true;
          } // console.log(this, videoSetup);


          originalSetup.call(this, videoSetup); //testVid.call(this, videoSetup);
        };
      } catch (e) {
        console.log('No MelaVideoPlayer object');
      }
    }, 500); // Check if element is visible

    function isVisible(elem) {
      if (!(elem instanceof Element)) throw Error('DomUtil: elem is not an element.');
      var style = getComputedStyle(elem);
      if (style.display === 'none') return false;
      if (style.visibility !== 'visible') return false;
      if (style.opacity < 0.1) return false;

      if (elem.offsetWidth + elem.offsetHeight + elem.getBoundingClientRect().height + elem.getBoundingClientRect().width === 0) {
        return false;
      }

      var elemCenter = {
        x: elem.getBoundingClientRect().left + elem.offsetWidth / 2,
        y: elem.getBoundingClientRect().top + elem.offsetHeight / 2
      };
      if (elemCenter.x < 0) return false;
      if (elemCenter.x > (document.documentElement.clientWidth || window.innerWidth)) return false;
      if (elemCenter.y < 0) return false;
      if (elemCenter.y > (document.documentElement.clientHeight || window.innerHeight)) return false;
      var pointContainer = document.elementFromPoint(elemCenter.x, elemCenter.y);

      do {
        if (pointContainer === elem) return true;
      } while (pointContainer = pointContainer.parentNode);

      return false;
    }

    function isVisibleSimple(elem) {
      return !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
    }

    function getEcommerceCurrencyType(n) {
      switch (n.toLowerCase()) {
        case 'en-us':
        case 'es-us':
          return 'USD';

        case 'es-mx':
          return 'MXN';

        case 'en-ca':
        case 'fr-ca':
          return 'CAD';

        case 'en-au':
          return 'AUD';

        case 'en-nz':
          return 'NZD';

        case 'en-gb':
          return 'GBP';

        case 'en-ie':
        case 'nl-nl':
        case 'de-de':
        case 'de-at':
          return 'EUR';

        case 'pl-pl':
          return 'PLN';

        case 'en-sg':
        case 'zh-sg':
          return 'SGD';

        case 'en-my':
        case 'zh-my':
          return 'MYR';

        case 'ja-jp':
          return 'JPY';

        case 'ko-kr':
          return 'KRW';

        case 'zh-cn':
          return 'CNY';

        case 'zh-tw':
          return 'TWD';

        case 'zh-hk':
          return 'HKD';
      }

      return null;
    } // Compare 2 arrays and return the difference (Using stringify strategy O(n))


    function arr_diff(md1, md2) {
      var resultMd1 = md2.filter(function (elm) {
        return !md1.map(function (elm) {
          return JSON.stringify(elm);
        }).includes(JSON.stringify(elm));
      });
      var resultMd2 = md1.filter(function (elm) {
        return !md2.map(function (elm) {
          return JSON.stringify(elm);
        }).includes(JSON.stringify(elm));
      });
      var difference = [].concat(_toConsumableArray(resultMd1), _toConsumableArray(resultMd2));

      if (difference.length > 0) {
        return difference;
      } else {
        return false;
      }
    }
    /*******************************
     * Google Tag Manager Functions
     *******************************/


    localStorage.removeItem('mela-cart');
    var listToReplace = {
      ProductStore: 'ProductCategory',
      Product: 'ProductDetail',
      'search-results': 'SearchResults',
      CheckoutPromo: 'ProductCheckoutPromo'
    };
    var locale = PageClientData.UICulture.replace('-', '_').toLowerCase();
    var currencyCode = getEcommerceCurrencyType(PageClientData.UICulture); // Establish the appropriate API path:

    var localeApi = locale.split('_')[0];
    var apiPath = 'api-' + localeApi; // fallback. defined in $(window).on("load") event below

    if (window.location.href.indexOf('webcontent')) {
      apiPath = 'api-' + localeApi + '-dv'; // fallback. defined in $(window).on("load") event below
    }

    var customerStatusLevels = {
      regular: 'regular',
      direct: 'regular',
      preferred: 'preferred',
      vip: 'vip',
      silver: 'vip',
      gold: 'vip',
      platinum: 'vip',
      specialpreferred: 'vip'
    };
    var custStatus = 'regular';

    if (PageClientData.CustomerStatus) {
      custStatus = customerStatusLevels[PageClientData.CustomerStatus.toLowerCase()];
    }

    var pageType = $('meta[name="pageType"]').attr('content');

    if (!isNaN(pageType)) {
      pageType = $('meta[name="siteSection"]').attr('content');
    }

    var listToUse = pageType;

    if (!isNaN(listToUse)) {
      listToUse = 'ProductCategory';
    }

    $.each(listToReplace, function (i, v) {
      if (i == listToUse) {
        listToUse = listToUse.replace(i, v);
      }
    });
    var matches = /sku=(\d+)/;

    function getImpressions(className) {
      /****
       * Records the impressions on the page
       ****/
      var impressionClasses = new Array();
      var sidebar = false;

      if (className) {
        impressionClasses = document.getElementsByClassName(className)[0].querySelectorAll("a[href*='?sku=']:not([data-aid])");

        if (impressionClasses.length <= 0) {
          sidebar = true;
          impressionClasses = document.getElementsByClassName(className)[0].getElementsByClassName('atc');
        }
      } else {
        impressionClasses = document.querySelectorAll("a[href*='?sku=']:not([data-aid])");
      }

      var additionalSkus = new Array();

      if ($('#recommendationsPlaceholder').length > 0) {
        var thisSku = ''; // Product Detail pages - this gets the recommended products on the right sidebar

        setTimeout(function () {
          $('.recItemContent').each(function () {
            if (!$(this).attr('id')) {
              $(this).attr('id', 'checkvis');
            }

            var checkthis = document.getElementById($(this).attr('id'));
            thisSku = $(this).find('.addToCartWrapper .atc').attr('sku');

            if (thisSku && isVisible(checkthis)) {
              additionalSkus.push(thisSku);
            }

            if ($(this).attr('id') == 'checkvis') {
              $(this).attr('id', '');
            }
          });
        }, 500);
      }

      var numSeconds = 0;
      var checkImpressions = setInterval(function () {
        if (impressionClasses.length > 0) {
          var impressionsFiltered = new Array();

          for (var _i = 0, _Object$entries = Object.entries(impressionClasses); _i < _Object$entries.length; _i++) {
            var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
                k = _Object$entries$_i[0],
                v = _Object$entries$_i[1];

            if (isVisibleSimple(v)) {
              // determines if element is visible
              if (!sidebar) {
                impressionsFiltered.push(v.href.match(matches)[1]);
              } else {
                impressionsFiltered.push(v.attributes.sku);
              }
            }
          }

          impressionsFiltered = Array.from(new Set(impressionsFiltered));

          if ($('#recommendationsPlaceholder').length > 0) {
            if (impressionsFiltered.length > 0 && additionalSkus.length > 0) {
              impressionsFiltered = impressionsFiltered.concat(additionalSkus);
              clearInterval(checkImpressions);
              ga_impressions(impressionsFiltered);
            }
          } else {
            if (impressionsFiltered.length > 0) {
              clearInterval(checkImpressions);
              ga_impressions(impressionsFiltered);
            }
          }

          if (numSeconds >= 50) {
            clearInterval(checkImpressions);
          }
        }

        numSeconds += 1;
      }, 100);
    }

    String.prototype.capitalize = function () {
      return this.replace(/(?:^|\s)\S/g, function (a) {
        return a.toUpperCase();
      });
    };

    function ga_impressions(skus) {
      /****
       * Sends information to GA about product(s) that
       * have been viewed at any page of the site (some
       * checkout pages excluded)
       ****/
      var prodSku;
      var prodName;
      var prodPricePref;
      var prodPriceReg;
      var prodPriceVip;
      var addProd = [];
      var promise = new Promise(function (resolve, reject) {
        var _loop = function _loop() {
          var _Object$entries2$_i = _slicedToArray(_Object$entries2[_i2], 2),
              idx = _Object$entries2$_i[0],
              sku = _Object$entries2$_i[1];

          $.get('https://' + apiPath + '.melaleuca.com/search/v3', {
            id: 'products_by_sku',
            index: locale,
            params: JSON.stringify({
              skus: [sku.toString()],
              size: 1000
            })
          }).error(function (err) {
            return 'Sku ' + sku + ' not found. | ' + err.responseText;
          }).done(function (data) {
            if (data.hits.hits.length > 0) {
              prodSku = data.hits.hits[0]._source.sku;

              if (prodSku && prodSku !== '') {
                prodName = data.hits.hits[0]._source.name.enhanced.replace(/<[^>]*>?/gm, '');
                prodPriceReg = data.hits.hits[0]._source.price.retail;
                prodPricePref = data.hits.hits[0]._source.price.preferred;
                prodPriceVip = data.hits.hits[0]._source.price.vip;
                var price = prodPriceReg;

                if (custStatus === 'preferred') {
                  price = prodPricePref;
                } else if (custStatus === 'vip') {
                  price = prodPriceVip;
                }

                var category = '';

                if ($('.crumb').length) {
                  var c = 1;
                  $('.crumb').each(function () {
                    if (c !== $('.crumb').length) {
                      category += $(this).text() + '/';
                    }

                    c += 1;
                  });
                  category = category.replace(/\/$/, ''); // remove trailing slash
                }

                var variant = '';

                if (data.hits.hits[0]._source.size) {
                  variant = data.hits.hits[0]._source.size.value + ' ' + data.hits.hits[0]._source.size.type;
                }

                price = currency(price); // per item

                addProd.push({
                  id: prodSku,
                  name: prodName,
                  price: price.toString(),
                  brand: '',
                  category: category,
                  variant: variant,
                  list: listToUse,
                  // should indicate which page they're on?
                  position: parseInt(idx) + 1
                });

                if (addProd.length === skus.length) {
                  dataLayer.push({
                    event: 'productImpressions',
                    ecommerce: {
                      currencyCode: currencyCode,
                      impressions: addProd
                    }
                  });
                }
              }
            }
          });
        };

        for (var _i2 = 0, _Object$entries2 = Object.entries(skus); _i2 < _Object$entries2.length; _i2++) {
          _loop();
        }

        if (dataLayer) {
          resolve(dataLayer);
        } else {
          reject('Unable to write to dataLayer (impressions).');
        }
      }); // promise
    }

    function ga_addProduct(existingItems, curItems, quantity) {
      // Find last item (the most recently added) and push the data to dataLayer
      // console.log('inside add products function');
      var lastItem = curItems[curItems.length - 1];
      var price = lastItem.Tier1Price;

      if (custStatus == 'preferred') {
        price = lastItem.Tier2Price;
      }

      price = currency(price) / lastItem.Quantity; // per item

      if ($('.MinCartTable').length) {
        $('td.miniCartStyle.sku').each(function () {
          var thisSku = $(this).text().trim();

          if (thisSku == lastItem.SKU) {
            $.each(existingItems, function (i, v) {
              if (existingItems[i].SKU == thisSku) {
                // this item is already in the cart. Get the difference between the existing quantity and the new quantity.
                lastItem.Quantity = lastItem.Quantity - existingItems[i].Quantity;
              }
            });
          }
        });
      } else if ($('.cartButton').length) {
        lastItem.Quantity = quantity; //set quantity value of the last item added in mobile
      } // console.log('above addtocart datalayer');
      // Could probably get brand, category, and variant if I did an API call on the sku here...


      dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode: currencyCode,
          add: {
            products: [{
              id: lastItem.SKU,
              name: lastItem.DescriptionNoHTML,
              price: price.toString(),
              brand: '',
              category: '',
              variant: '',
              quantity: lastItem.Quantity
            }]
          }
        }
      });
    }

    function ga_removeProduct(diff) {
      $.each(diff, function (k, v) {
        var price = v.Tier1Price;

        if (custStatus == 'preferred') {
          price = v.Tier2Price;
        }

        price = currency(price) / v.Quantity; // per item
        // Could probably get brand, category, and variant if I did an API call on the sku here...

        dataLayer.push({
          event: 'removeFromCart',
          ecommerce: {
            currencyCode: currencyCode,
            remove: {
              products: [{
                id: v.SKU,
                name: v.DescriptionNoHTML,
                price: price.toString(),
                brand: '',
                category: '',
                variant: '',
                quantity: v.Quantity
              }]
            }
          }
        });
      });
    }

    $(document).on('cartstatechange', function (event, qty) {
      if ($('.MiniCartTable').length || $('.cartButton').length) {
        $.get('/UtilityWebHelperApis/MiniCart/GetCart').then(function (r) {
          if (sessionStorage.getItem('mela-cart')) {
            var existingItems = JSON.parse(sessionStorage.getItem('mela-cart')).Items; // Items array for the items in the cart

            var numExisting = JSON.parse(sessionStorage.getItem('mela-cart')).CartCount; //number of total products in cart stored in session storage
          }

          if (sessionStorage.getItem('mela-cart') !== JSON.stringify(r)) {
            //comparing session storage with current cart items
            sessionStorage.setItem('mela-cart', JSON.stringify(r));
            var curItems = r.Items; //store current cart products

            var numCurrent = r.CartCount; //store current cart count

            if (numCurrent > numExisting) {
              // console.log('making addProduct call');
              if ($('.cartButton').length) ;

              ga_addProduct(existingItems, curItems, qty);
            } else if (numCurrent < numExisting) {
              // console.log('inside else');
              if (curItems.length && existingItems.length) {
                var diff = arr_diff(curItems, existingItems); //find difference between current cart and session storage cart products
                // console.log(diff);
              } else {
                var diff = existingItems;
              }

              ga_removeProduct(diff);
            }
          }
        });
      }
    }); // Track if someone clicks to view a product. Again, looks for "?sku=" in <a> tags (href property)

    $(document).on('click', "a[href*='?sku=']:not([data-aid])", function (e) {
      e.preventDefault();
      var url = $(this).attr('href');
      var sku = $(this).attr('href').match(matches)[1];
      $.get('https://' + apiPath + '.melaleuca.com/search/v3', {
        id: 'products_by_sku',
        index: locale,
        params: JSON.stringify({
          skus: [sku.toString()],
          size: 1000
        })
      }).error(function (err) {
        return 'Sku ' + sku + ' not found. | ' + err.responseText;
      }).done(function (data) {
        if (data.hits.hits[0]) {
          ga_clicks(data.hits.hits[0], url);
        } else {
          window.location = url, true;
        }
      });
    });

    function ga_clicks(productObj, url) {
      /****
       * Users clicked on a product listing
       * to view more details.
       ****/
      if (productObj && url) {
        var price = 0;

        if (custStatus == 'regular') {
          price = productObj._source.price.retail;
        } else {
          price = productObj._source.price[custStatus];
        }

        var prodPosition = 0;
        $.each(dataLayer, function (k, v) {
          if (typeof v.ecommerce != 'undefined') {
            $.each(v.ecommerce, function (k2, v2) {
              if (k2 == 'impressions') {
                $.each(v2, function (k3, v3) {
                  if (v3.id == productObj._source.sku) {
                    prodPosition = v3.position;
                  }
                });
              }
            });
          }
        });
        var variant = '';

        if (productObj._source.size) {
          variant = productObj._source.size.value + ' ' + productObj._source.size.type;
        }

        var list = $('.crumbText a').last().text();

        if (!list || list !== '') {
          list = listToUse;
        }

        dataLayer.push({
          event: 'productClick',
          ecommerce: {
            currencyCode: currencyCode,
            click: {
              actionField: {
                list: list
              },
              products: [{
                id: productObj._source.sku,
                name: productObj._source.name.enhanced.replace(/<[^>]*>?/gm, ''),
                price: price,
                brand: '',
                category: '',
                variant: variant,
                position: prodPosition
              }]
            }
          },
          eventCallback: function eventCallback() {
            window.location = url, true;
          }
        });
      } else {
        window.location = url, true;
      }
    }

    function ga_detailpage(_inc) {
      /****
       * Collects data about the product pages the
       * user has viewed.
       ****/
      if (window.productDetail && window.productDetail.Product) {
        var price = 0;

        if (custStatus == 'preferred' || custStatus == 'vip') {
          price = productDetail.Product.Economics.Tier2Price;
        } else {
          price = productDetail.Product.Economics.Tier1Price;
        }

        price = currency(price); // per item

        var category = '';

        if ($('.crumb').length) {
          var c = 1;
          $('.crumb').each(function () {
            if (c !== $('.crumb').length) {
              category += $(this).text() + '/';
            }

            c += 1;
          });
          category = category.replace(/\/$/, ''); // remove trailing slash
        }

        var variant = '';

        if ($('#optionsList')) {
          $('#optionsList select').each(function () {
            variant += $(this).find('option[value="' + $(this).val() + '"]').text() + ' | ';
          });
        }

        var dpFound = false;
        /* Prevents duplicates. Not sure if we want this or not: */

        $.each(dataLayer, function (i, v) {
          if (dataLayer[i].ecommerce !== undefined) {
            if (dataLayer[i].ecommerce.detail !== undefined) {
              if (dataLayer[i].ecommerce.detail.products[0].id == productDetail.Product.Summary.Sku) {
                dpFound = true;
              }
            }
          }
        });

        if (!dpFound) {
          dataLayer.push({
            event: 'productDetail',
            ecommerce: {
              currencyCode: currencyCode,
              detail: {
                actionField: {
                  list: 'ProductDetail'
                },
                products: [{
                  id: productDetail.Product.Summary.Sku,
                  name: productDetail.Product.Summary.ShortName,
                  price: price.toString(),
                  brand: '',
                  category: category,
                  variant: variant.replace(/\|\s*$/, '').trim()
                }]
              }
            }
          });
        }
      } else if (_inc > 20) {
        return;
      } else {
        setTimeout(function () {
          ga_detailpage(_inc + 1);
        }, 300);
      }
    }

    function promotionView(promos) {
      /****
       * Collects data about promos/banners
       * a user has viewed.
       ****/
      if (promos.length > 0) {
        dataLayer.push({
          event: 'promoView',
          ecommerce: {
            promoView: {
              promotions: promos
            }
          }
        });
      }
    }

    function promotionClick(promoObj, promoUrl) {
      var target = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '_self';

      /****
       * Collects data about the promos/banners
       * a user has clicked.
       ****/
      if (promoObj.id) {
        dataLayer.push({
          event: 'promoClick',
          ecommerce: {
            promoClick: {
              promotions: [{
                id: promoObj.id,
                // ID of Promotion REQUIRED
                name: promoObj.name,
                creative: promoObj.creative,
                position: promoObj.position
              }]
            }
          }
        });
      }

      if (promoUrl && target == '_self') {
        // Default target
        window.location = promoUrl, true;
      } else if (promoUrl && target !== '_self') {
        // If target is set, honor it
        window.open(promoUrl, target);
      } else ;
    }

    apiPath = amzSearchApiHosts.prod;

    if (window.location.href.indexOf('webcontent') >= 0) {
      apiPath = amzSearchApiHosts.preProd;
    } // GET EXISTING CoreMetrics TAGS AND CONVERT THEM TO A GENERIC data-aid TAG.
    // This can probably be removed when all manual_cm_re tags have been removed from the site.


    $('a[manual_cm_re]').each(function () {
      var attr = $(this).attr('manual_cm_re').split('-');

      if (attr[0] == 'search') {
        var attrCat = '@';
        var attrSku = '@';

        if (attr[2].indexOf('c') == 0) {
          attrCat = attr[2].split('c')[1];
        } else if (!isNaN(attr[2])) {
          attrSku = attr[2];
        } else {
          attrSku = '@';
        }

        $(this).attr('data-aid', '@|@|search-banner|' + attrCat + '|' + attrSku);
      } else {
        $.each(attr, function (i, v) {
          if (!v || v == '_') {
            attr[i] = '@';
          }
        });
        var attrCat = '@';
        var attrSku = '@';

        if (attr[4]) {
          if (attr[4].indexOf('c') == 0) {
            attrCat = attr[4].split('c')[1];
          } else if (!isNaN(attr[4])) {
            attrSku = attr[4];
          } else {
            attrSku = '@';
          }
        }

        $(this).attr('data-aid', attr[0] + '|' + attr[1] + '|' + attr[2] + '|' + attrCat + '|' + attrSku);
      }
    }); // Track product impressions (looks for "?sku=" in <a> tags (href property))

    setTimeout(function () {
      if ($('.MiniCartTable').length > 0 || $('#mobile-header #btnShowMiniCart').length > 0) {
        getImpressions();
      }
    }, 100); // Due to Angular's slight delay, need to delay this

    if (document.getElementById('btnShowMiniCart')) {
      document.getElementById('btnShowMiniCart').addEventListener('click', function () {
        if (!$('#mycart').is(':hidden')) {
          trackAction({
            id: 42
          });
        }
      }, false);
    }

    $(document).on('click', '.carousel-nav', function () {
      getImpressions();
    });
    var addToCartButtons = document.querySelectorAll('button[id*=addToCartBtn]');
    addToCartButtons = Array.prototype.slice.call(addToCartButtons); //converts to array of nodes matching the above id

    addToCartButtons.forEach(function (item) {
      item.addEventListener('click', function (event) {
        // console.log('inside addeventlistner');
        var impressions = [];

        if (typeof mostPopular !== 'undefined') {
          var _iterator = _createForOfIteratorHelper(mostPopular.entries()),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var _step$value = _slicedToArray(_step.value, 2),
                  index = _step$value[0],
                  product = _step$value[1];

              impressions.push({
                id: product.sku,
                name: product.contenttitle,
                price: product.regularprice.toString(),
                brand: '',
                category: '',
                variant: '',
                list: '',
                position: index + 1
              });
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }

          dataLayer.push({
            event: 'productImpressions',
            ecommerce: {
              currencyCode: currencyCode,
              impressions: impressions
            }
          });
        }
      });
    });
    var navSubItems = document.querySelectorAll('.nav-sub-item');
    navSubItems = Array.prototype.slice.call(navSubItems); // Track product impressions in submenu

    navSubItems.forEach(function (item) {
      item.addEventListener('mouseover', function (event) {
        var impressions = [];

        if (typeof mostPopular !== 'undefined') {
          var _iterator2 = _createForOfIteratorHelper(mostPopular.entries()),
              _step2;

          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var _step2$value = _slicedToArray(_step2.value, 2),
                  index = _step2$value[0],
                  product = _step2$value[1];

              impressions.push({
                id: product.sku,
                name: product.contenttitle,
                price: product.regularprice.toString(),
                brand: '',
                category: '',
                variant: '',
                list: '',
                position: index + 1
              });
            }
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }

          dataLayer.push({
            event: 'productImpressions',
            ecommerce: {
              currencyCode: currencyCode,
              impressions: impressions
            }
          });
        }
      });
    }); // When the mini-cart is closed, cartstatechange is triggered, but this forces it to remove an item immediately when the 'X' is clicked.

    if (!sessionStorage.getItem('mela-cart')) {
      // console.log('setting the mela-cart object in sessionstorage');
      $(document).trigger('cartstatechange'); // set current cart into sessionStorage when mela-cart object doesn't exist
    } //Runs only for mobile since cartstatechange is not automatically triggered on adding product to cart


    if ($('.cartButton').length) {
      var oldcmCreateShopAction5Tag = cmCreateShopAction5Tag;
      setTimeout(function () {
        window.cmCreateShopAction5Tag = function () {
          // console.log('something was added to cart', arguments);
          var qty = [].slice.call(arguments)[2]; //product quantity added to cart
          // console.log('qty: ', qty);

          $(document).trigger('cartstatechange', qty); //trigger cartstatechange only on addtocart event in mobile

          oldcmCreateShopAction5Tag.apply(null, [].slice.call(arguments));
        };
      }, 10);
    }

    function removeItem() {
      $.get('/UtilityWebHelperApis/MiniCart/GetCart').then(function (r) {
        if (localStorage.getItem('mela-cart')) {
          var existingItems = JSON.parse(localStorage.getItem('mela-cart')).Items;
        }

        if (localStorage.getItem('mela-cart') !== JSON.stringify(r)) {
          localStorage.setItem('mela-cart', JSON.stringify(r));
          var curItems = r.Items;

          if (curItems.length > 0 && existingItems.length > 0) {
            var diff = arr_diff(curItems, existingItems); // console.log(diff);
          } else {
            var diff = existingItems;
          }

          ga_removeProduct(diff);
        }
      });
    }

    var startCartRows = $('.MiniCartTable tbody tr').length;
    var detectMiniCartReduction = setInterval(function () {
      // A click event wasn't working, so this detects a reduction in the number of cart rows
      var numRows = $('.MiniCartTable tbody tr').length;

      if (numRows < startCartRows) {
        removeItem();
      }
    }, 100);
    setInterval(function () {
      // If someone hovers over a menu item and a product is shown in the popup, record the impression (promoView) and track clicks (promoClick):
      var processSku = true;

      if ($('.nav-item-popup.is-visible').find('[data-aid]').length > 0) {
        var checkId = $('.nav-item-popup.is-visible').find('[data-aid]').data('aid');
        $.each(dataLayer, function (k, v) {
          if (v.ecommerce) {
            if (v.ecommerce.promoView) {
              $.each(v.ecommerce.promoView.promotions, function (k2, v2) {
                if (v2.id == checkId) {
                  processSku = false;
                }
              });
            }
          }
        });
        var promos = [];

        if (processSku && $('.MiniCartTable').length) {
          processSku = false;

          if ($('.nav-item-popup.is-visible').find('[data-aid]').data('aid')) {
            var promoAll = $('.nav-item-popup.is-visible').find('[data-aid]').data('aid').split('|');
            var promoName = $('.nav-item-popup.is-visible').find('[data-aid]').data('aname') ? $('.nav-item-popup.is-visible').find('[data-aid]').data('aname') : '';
            var promoId = $('.nav-item-popup.is-visible').find('[data-aid]').data('aid'),
                promoCreative = promoAll[5] && promoAll[5] !== '@' ? promoAll[5] : '',
                promoPosition = promoAll[2] && promoAll[2] !== '@' ? promoAll[2] : '';
            promos.push({
              id: promoId,
              name: promoName,
              creative: promoCreative,
              position: promoPosition
            });
            promotionView(promos);
          }
        }
      }
    }, 500); // Any faster and we'll get duplicates in the 'impressions' array

    $(document).on('click', '.carousel-nav, .jcarousel-control-next, .jcarousel-control-prev', function (e) {
      var thisCarousel = $(this).closest('div[class*="wrapper"]').attr('class');

      if (thisCarousel) {
        // console.log('thiisud');
        getImpressions(thisCarousel);
      }
    }); // This function is not working. _this_ is undefined.

    $(document).on('click', '.recPaging', function (e) {
      var thisCarousel = $(this).closest('div[class*="recItemContainer"]').attr('class');

      if (thisCarousel) {
        getImpressions(thisCarousel);
      }
    });
    $(document).on('click', '[onclick*="updateProductDetailJson"]', function (e) {
      var productObj = e.target.attributes.onclick.nodeValue.split('(')[1].split(')')[0].split(', ');
      var prodSku = productObj[0].replace(/'+/g, '');
      var prodPosition = $(this).closest('.recItem').index() + 1;
      $.get('https://' + apiPath + '.melaleuca.com/search/v3', {
        id: 'products_by_sku',
        index: locale,
        params: JSON.stringify({
          skus: [prodSku],
          size: 1000
        })
      }).error(function (err) {
        return 'Sku ' + prodSku + ' not found. | ' + err.responseText;
      }).done(function (data) {
        if (data.hits.hits[0]) {
          var prodName = data.hits.hits[0]._source.name.enhanced.replace(/<[^>]*>?/gm, '');

          var prodPricePref = data.hits.hits[0]._source.price.preferred;
          var prodPriceReg = data.hits.hits[0]._source.price.retail; //var prodPriceVip = data.hits.hits[0]._source.price.vip;

          var prodPrice = prodPriceReg;

          if (custStatus == 'preferred' || custStatus == 'vip') {
            prodPrice = prodPricePref;
          }

          var prodVariant = '';

          if (data.hits.hits[0]._source.size.value) {
            prodVariant += +data.hits.hits[0]._source.size.value;
          }

          if (data.hits.hits[0]._source.size.type.type) {
            prodVariant += ' ' + data.hits.hits[0]._source.size.type.type;
          }

          dataLayer.push({
            event: 'productClick',
            ecommerce: {
              currencyCode: currencyCode,
              click: {
                actionField: {
                  list: 'productDetail'
                },
                products: [{
                  id: prodSku,
                  name: prodName,
                  price: prodPrice.toString(),
                  brand: '',
                  category: '',
                  variant: prodVariant,
                  position: prodPosition
                }]
              }
            }
          });
        }
      });
    }); // Track detail page views

    if (/productstore\/product$/i.test(location.pathname)) {
      ga_detailpage(1);
    }
    /**************************
     * Checkout pages:
     * ***********************/


    var step = null;
    var stepName = null;
    var eventName = null;

    if (pageType == 'Cart') {
      // Step 1
      if ($('#cartTblContainer').length > 0 || $('#dvCartItemsContainer').length > 0) {
        // Record skyscraper impression:
        var skyscraper = false;

        if ($('#skyScraperWrapper').length > 0 && $('#skyScraperWrapper a').attr('onclick')) {
          skyscraper = true;
          var sku = [$('#skyScraperWrapper a').attr('onclick').split('(').pop().split(',')[0].toString()];
        } else if ($('#skyScraperWrapper').length > 0 && !$('#skyScraperWrapper a').attr('onclick')) {
          skyscraper = true;
          var base_element = $('#skyScraperWrapper a').attr('data-aid');
          var sku = 0;

          if (base_element) {
            sku = [base_element.split('|').pop().toString()];
          }
        }

        if (skyscraper && sku && !isNaN(sku)) {
          $.get('https://' + apiPath + '.melaleuca.com/search/v3', {
            id: 'products_by_sku',
            index: locale,
            params: JSON.stringify({
              skus: sku,
              size: 1000
            })
          }).error(function (err) {
            return 'Sku ' + sku + ' not found. | ' + err.responseText;
          }).done(function (data) {
            var prodSku;
            var prodName;
            var prodPricePref;
            var prodPriceReg;
            var prodPriceVip;

            if (data.hits.hits.length > 0) {
              prodSku = data.hits.hits[0]._source.sku;
              prodName = data.hits.hits[0]._source.name.enhanced.replace(/<[^>]*>?/gm, '');
              prodPriceReg = data.hits.hits[0]._source.price.retail;
              prodPricePref = data.hits.hits[0]._source.price.preferred;
              prodPriceVip = data.hits.hits[0]._source.price.vip;
              var price = prodPriceReg;

              if (custStatus == 'preferred' || custStatus == 'vip') {
                price = prodPricePref;
              }

              price = currency(price); // per item

              dataLayer.push({
                event: 'productImpressions',
                ecommerce: {
                  currencyCode: currencyCode,
                  impressions: [{
                    id: prodSku,
                    name: prodName,
                    price: price.toString(),
                    brand: '',
                    category: '',
                    variant: '',
                    list: '',
                    position: 1
                  }]
                }
              });
            }

            var promoObj = [];
            $('#skyScraperWrapper').on('click', 'a', function () {
              promoObj.push({
                id: $(this).data('aid'),
                name: '',
                creative: '',
                position: $(this).data('aid').split('|')[2] !== '@' ? $(this).data('aid').split('|')[2] : ''
              });
              promotionClick(promoObj, null, null);
            });
          });
        }

        var prodSkus = [];
        var prodQtys = [];
        var products = [];
        var addProd = [];
        $('.cartProdRow').each(function () {
          // Desktop
          prodSkus.push($(this).attr('id').replace('trItem', ''));
        });
        $('.cartQtyRow').each(function () {
          // Desktop
          prodQtys.push($(this).find('input').val());
        });
        $('.product-wrapper').each(function () {
          //Mobile
          prodSkus.push($(this).attr('id').replace('product_', ''));
        });
        $.get('https://' + apiPath + '.melaleuca.com/search/v3', {
          id: 'products_by_sku',
          index: locale,
          params: JSON.stringify({
            skus: prodSkus,
            size: 1000
          })
        }).error(function (err) {
          return 'Sku ' + sku + ' not found. | ' + err.responseText;
        }).done(function (data) {
          $.each(data.hits.hits, function (idx, prods) {
            var variant = '';

            if (prods._source.size) {
              variant = prods._source.size.value + ' ' + prods._source.size.type;
            }

            var prodName = prods._source.name.enhanced.replace(/<[^>]*>?/gm, '');

            var prodPriceReg = prods._source.price.retail;
            var prodPricePref = prods._source.price.preferred;
            var prodPriceVip = prods._source.price.vip;
            var price = prodPriceReg;

            if (custStatus === 'preferred') {
              price = prodPricePref;
            } else if (custStatus === 'vip') {
              price = prodPriceVip;
            }

            addProd = {
              id: prods._source.sku,
              name: prodName,
              quantity: prodQtys[idx],
              price: price,
              brand: '',
              category: '',
              variant: variant,
              list: ''
            };
            products.push(addProd);
          });
          dataLayer.push({
            event: 'viewCart',
            ecommerce: {
              checkout: {
                actionField: {
                  step: 1,
                  option: $('.cartHeaderWrapper .cartHeaderLeft').text().trim()
                }
              },
              products: products
            }
          });
        });
      }
    } else if (pageType == 'CheckoutPromo') {
      // Step 2
      eventName = 'checkoutOption';
      step = 2;
      stepName = 'Checkout Promo'; // Record addition of promo offer

      $(document).on('click', '.offer-card__offer-cta-btn', function () {
        var promoCartItems = document.getElementsByClassName('cart-offer-card__title');
        var promoCartItemsLn = promoCartItems.length;
        var sku = $(this).closest('.showButton').attr('id').split('_')[1];
        var name = $(this).closest('.offer-card__details-wrapper').find('.offer-card__title').text().trim();
        var cartQuantities = new Array();
        $('.cart-offers-list li').each(function () {
          var thisQuantityString = $(this).find('.cart-offer-card__qty').text().trim();
          cartQuantities[sku] = thisQuantityString.match(/(\d+)/)[0];
        });
        var recordAddition = setInterval(function () {
          var newCartItems = document.getElementsByClassName('cart-offer-card__title');
          var newCartItemsLn = newCartItems.length;
          var quantityChanged = false;
          $('.cart-offers-list li').each(function () {
            var newQuantityString = $(this).find('.cart-offer-card__qty').text();

            if (cartQuantities[sku] !== newQuantityString.match(/(\d+)/)[0]) {
              cartQuantities[sku] = newQuantityString.match(/(\d+)/)[0];
              quantityChanged = true;
            }
          });

          if (newCartItemsLn > promoCartItemsLn || quantityChanged) {
            clearInterval(recordAddition);
            var price = currency($('.cart-offers-list li:last-child').find('.cart-offer-card__price').text());
            dataLayer.push({
              event: 'addToCart',
              ecommerce: {
                currencyCode: currencyCode,
                add: {
                  products: [{
                    id: sku,
                    name: name,
                    price: (price / cartQuantities[sku]).toString(),
                    brand: '',
                    category: '',
                    variant: '',
                    quantity: 1 // no matter what, on this page, you can only add 1 at a time

                  }]
                }
              }
            });
          }
        });
      }); // Record removal of promo offer

      $(document).on('click', '.cart-offer-card__delete', function () {
        var skuToRemove = $(this).attr('onclick').split('RemoveKitFromCartAndBogoUI(')[1].split(',')[0];
        var name = $(this).closest('.cart-offer-card').find('.cart-offer-card__title').text().trim();
        var quantity = $(this).closest('.cart-offer-card').find('.cart-offer-card__qty').text().trim().match(/(\d+)/)[0];
        var price = currency($(this).closest('.cart-offer-card').find('.cart-offer-card__price').text());
        dataLayer.push({
          event: 'addToCart',
          ecommerce: {
            currencyCode: currencyCode,
            remove: {
              products: [{
                id: skuToRemove,
                name: name,
                price: (price / quantity).toString(),
                brand: '',
                category: '',
                variant: '',
                quantity: quantity
              }]
            }
          }
        });
      });
    } else if (pageType == 'PaymentSelection') {
      // Step 3
      if (currency($('.payAmountBalance').text()) > 0 || $('#header > div:last-child > font:last-child').text().replace('| ', '').match(/\d/g).join('') > 0) {
        // the first check is for desktop, the 2nd for mobile /\
        eventName = 'checkoutOption';
        step = 3;
        stepName = $('.checkoutTitle').text().trim();

        if (!stepName) {
          stepName = $('#title h2').text().trim();
        }

        var stepName1 = stepName;
        var stepName2 = ''; // Check for a payment method and overwrite stepName with that:

        if ($('.paymethodSelectionContainer').find('input[checked="checked"]') && $('.paymethodSelectionContainer').find('input[checked="checked"]').next('label').text().trim() !== '') {
          stepName = $('.paymethodSelectionContainer').find('input[checked="checked"]').next('label').text().trim();

          if (!stepName) {
            //mobile
            stepName = $('input[name="selectPaymethodType"][checked="checked"]').closest('.custom-radio').next('c2').text();
          }
        } else if ($('input[name="payTypeSelectionGroup"][checked="checked"]') && $('input[name="payTypeSelectionGroup"][checked="checked"]').next('label').text().trim() !== '') {
          stepName1 = $('input[name="payTypeSelectionGroup"][checked="checked"]').next('label').text().trim();
          $('input[name="payTypeSelectionGroup"]').click(function () {
            stepName1 = $(this).next('label').text().trim(); // Change the dataLayer[x].ecommerce.checkout_option.actionField.option value

            $.each(dataLayer, function (i, v) {
              if (dataLayer[i].ecommerce) {
                if (dataLayer[i].ecommerce.checkout_option) {
                  dataLayer[i].ecommerce.checkout_option.actionField.option = stepName1;
                }
              }
            });
          });

          if ($('#PaymethodDisplay_ddlPaymentOptions option[selected="selected"]').text() !== '') {
            stepName2 = ' | ' + $('#PaymethodDisplay_ddlPaymentOptions option[selected="selected"]').text();
          }

          stepName = stepName1 + stepName2;
          $('#PaymethodDisplay_ddlPaymentOptions').change(function () {
            if ($('#PaymethodDisplay_ddlPaymentOptions').val() !== '') {
              stepName2 = ' | ' + $('#PaymethodDisplay_ddlPaymentOptions').val();
              stepName = stepName1 + stepName2; // Change the dataLayer[x].ecommerce.checkout_option.actionField.option value

              $.each(dataLayer, function (i, v) {
                if (dataLayer[i].ecommerce) {
                  if (dataLayer[i].ecommerce.checkout_option) {
                    dataLayer[i].ecommerce.checkout_option.actionField.option = stepName;
                  }
                }
              });
            }
          });
        } else if ($('input[name="selectPaymethodType"][checked="checked"]')) {
          // mobile
          stepName1 = $('input[name="selectPaymethodType"][checked="checked"]').closest('.c1').next('.c2').text().trim();
          $('body').on('click', '#radioContainer .custom-radio', function () {
            stepName1 = $(this).closest('.c1').next('.c2').text().trim(); // Change the dataLayer[x].ecommerce.checkout_option.actionField.option value

            $.each(dataLayer, function (i, v) {
              if (dataLayer[i].ecommerce) {
                if (dataLayer[i].ecommerce.checkout_option) {
                  dataLayer[i].ecommerce.checkout_option.actionField.option = stepName1;
                }
              }
            });
          });

          if ($('#ddlCVS option[selected="selected"]').text() !== '') {
            stepName2 = ' | ' + $('#ddlCVS option[selected="selected"]').text();
          }

          stepName = stepName1 + stepName2;
          $('#ddlCVS').change(function () {
            if ($('#ddlCVS').val() !== '') {
              stepName2 = ' | ' + $('#ddlCVS').val();
              stepName = stepName1 + stepName2; // Change the dataLayer[x].ecommerce.checkout_option.actionField.option value

              $.each(dataLayer, function (i, v) {
                if (dataLayer[i].ecommerce) {
                  if (dataLayer[i].ecommerce.checkout_option) {
                    dataLayer[i].ecommerce.checkout_option.actionField.option = stepName;
                  }
                }
              });
            }
          });
        }
      }
    } else if (pageType == 'SelectPaymethod') {
      // US/CA/MX version
      eventName = 'checkoutOption';
      step = 3;
      stepName = '';
    } else if (pageType == 'Checkout') {
      // Step 4
      eventName = 'checkoutOption';
      step = 4;
      stepName = $('.checkoutTitle').text().trim();
    } else if (pageType == 'latest') {
      // Step 5 (Order is complete; record all the data)
      var isJapan = $('#orderSummaryTable tbody tr').length;
      var isTaiwan = $('#orderReceiptTable #receiptDetailLineContainer').length;
      var isFRCanada = locale === 'fr_ca';
      var transactionID = '';
      var totalRevenue = '';
      var shipping = '';
      var tax = 0;
      var products = [];

      if (isJapan) {
        // Japan
        transactionID = $('.orderSummaryOrderDateInfo').text().trim();
        totalRevenue = currency($('td.grandTotal').text().trim());
        shipping = currency($('td.vatTotal').text().trim());
        tax = 0;
        $('tr > td.taxSummaryItem:nth-child(2)').each(function () {
          // This works for Japan ONLY;
          tax = tax + parseFloat(currency($(this).text().trim()));
        });
        $('#orderSummaryTable tbody tr').each(function () {
          var items = {};
          var sku = $(this).find('td.td1').text().trim().split(' -')[0];

          if (sku) {
            items[sku] = {};
            items[sku]['id'] = $(this).find('td.td2').text().trim().split(' -')[0];
            items[sku]['name'] = $(this).find('td.td2 span').text().trim();
            items[sku]['quantity'] = parseInt($(this).find('td.td1').text().trim());
            var price = 0;
            var metric1 = 0;

            if ($(this).find('td.td4').text().trim() == '-' || $(this).find('td.td6').text().trim() == '-') {
              price = 0;
              metric1 = $(this).find('td.td7').text().trim();
            } else if (custStatus == 'preferred' || custStatus == 'vip') {
              price = currency($(this).find('td.td6').text().trim()).value / items[sku]['quantity'];
            } else {
              price = currency($(this).find('td.td4').text().trim()).value / items[sku]['quantity'];
            }

            items[sku]['price'] = price.toString();
            items[sku]['brand'] = '';
            items[sku]['category'] = '';
            items[sku]['variant'] = '';
            items[sku]['quantity'] = parseInt($(this).find('td.td1').text().trim());
            items[sku]['coupon'] = '';
            items[sku]['metric1'] = metric1; // If points were used to purchase this item, put that here

            products.push(items[sku]);
          }
        });
      } else if (isTaiwan) {
        transactionID = $('#OrderNum').text().trim();
        totalRevenue = currency($('td#orderReceiptTotalsTableTotal').text().trim());
        shipping = currency($('td#orderReceiptTotalsTableShippingTotal').text().trim());
        tax = 0;
        /*$('tr > td.taxSummaryItem:nth-child(2)').each(function () { // This works for Japan ONLY;
                  tax = tax + parseFloat(currency($(this).text().trim()));
              });*/

        $('#orderReceiptTable #receiptDetailLineContainer').each(function () {
          var items = {};
          var sku = $(this).find('td.orderReceiptTableHeaderProductNumber').text().trim().split(' -')[0];

          if (sku) {
            items[sku] = {};
            items[sku]['id'] = sku;
            items[sku]['name'] = $(this).find('td.orderReceiptTableHeaderProductDescription').text().trim();
            items[sku]['quantity'] = parseInt($(this).find('td.orderReceiptTableHeaderProductQuantity').text().trim());
            var price = 0;
            var metric1 = 0;

            if ($(this).find('td.orderReceiptTableHeaderProductRegularPrice').text().trim() == '-' || $(this).find('td.orderReceiptTableHeaderProductPreferredPrice').text().trim() == '-') {
              price = 0;
              metric1 = $(this).find('td.orderReceiptTableHeaderProductPoints').text().trim();
            } else if (custStatus == 'preferred' || custStatus == 'vip') {
              price = currency($(this).find('td.orderReceiptTableHeaderProductPreferredPrice').text().trim()).value / items[sku]['quantity'];
            } else {
              price = currency($(this).find('td.orderReceiptTableHeaderProductRegularPrice').text().trim()).value / items[sku]['quantity'];
            }

            items[sku]['price'] = price.toString();
            items[sku]['brand'] = '';
            items[sku]['category'] = '';
            items[sku]['variant'] = '';
            items[sku]['coupon'] = '';
            items[sku]['metric1'] = metric1; // If points were used to purchase this item, put that here

            products.push(items[sku]);
          }
        });
      } else if ($('.order-products-table').length) {
        var _orderReceiptDesktop = orderReceiptDesktop(transactionID, isFRCanada, totalRevenue, shipping, tax, products, custStatus);

        var _orderReceiptDesktop2 = _slicedToArray(_orderReceiptDesktop, 5);

        transactionID = _orderReceiptDesktop2[0];
        totalRevenue = _orderReceiptDesktop2[1];
        shipping = _orderReceiptDesktop2[2];
        tax = _orderReceiptDesktop2[3];
        products = _orderReceiptDesktop2[4];
      } else if ($('.ProductsOrderedTitleMobile').length) {
        var _orderReceiptMobile = orderReceiptMobile(transactionID, isFRCanada, totalRevenue, shipping, tax, products, custStatus);

        var _orderReceiptMobile2 = _slicedToArray(_orderReceiptMobile, 5);

        transactionID = _orderReceiptMobile2[0];
        totalRevenue = _orderReceiptMobile2[1];
        shipping = _orderReceiptMobile2[2];
        tax = _orderReceiptMobile2[3];
        products = _orderReceiptMobile2[4];
      } // console.log('before datalayer');


      dataLayer.push({
        event: 'purchase',
        ecommerce: {
          currencyCode: currencyCode,
          purchase: {
            actionField: {
              id: transactionID,
              revenue: totalRevenue.toString(),
              tax: tax.toString(),
              shipping: shipping.toString(),
              coupon: '',
              affiliation: 'Online Store'
            },
            products: products
          }
        }
      });
    }

    var recordStep = setInterval(function () {
      if (step && (stepName || stepName == '')) {
        clearInterval(recordStep);
        dataLayer.push({
          event: eventName,
          ecommerce: {
            checkout_option: {
              actionField: {
                step: step,
                option: stepName
              }
            }
          }
        });
      }
    }, 100); // Record if skyscraper items added to cart:

    /*$('#skyScraperWrapper').on('click','a',function(){
          if ($(this).attr('onclick')){
              var sku = $(this).attr('onclick').split("(").pop().split(",")[0];
          } else if ($(this).data('aid')){
              var sku = $(this).data('aid').split("|").pop();
          }
          if (sku && !isNaN(sku)){
              $.get("https://"+apiPath+".melaleuca.com/search/v3",{
                  id:"products_by_sku",
                  index:locale,
                  params: JSON.stringify({
                      "skus":[sku.toString()],
                      "size":1000
                  })
              }).error(function(err){
                  return "Sku "+sku+" not found. | "+err.responseText;
              }).done(function(data){
                  var prodSku;
                  var prodName;
                  var prodPricePref;
                  var prodPriceReg;
                  //var prodPriceVip;
                  if (data.hits.hits.length > 0){
                      prodSku = data.hits.hits[0]._source.sku;
                      prodName = data.hits.hits[0]._source.name.enhanced.replace(/<[^>]*>?/gm, '');
                      prodPriceReg = data.hits.hits[0]._source.price.retail;
                      prodPricePref = data.hits.hits[0]._source.price.preferred;
                      //prodPriceVip = data.hits.hits[0]._source.price.vip;
                      var price = prodPriceReg;
                      if (custStatus === "preferred" || custStatus === "vip"){
                          price = prodPricePref;
                      }
                      price = currency(price); // per item
                      dataLayer.push({
                          'event': 'addToCart',
                          'ecommerce': {
                              'currencyCode': currencyCode,
                              'add': {
                                  'products': [{
                                      'id': prodSku,
                                      'name': prodName,
                                      'price': price.toString(),
                                      'brand': '',
                                      'category': '',
                                      'variant': '',
                                      'quantity': 1
                                  }]
                              }
                          }
                      });
                  }
              });
          }
      });*/
    // Record anytime an accessory is added to the cart (pump, compact, etc.)

    $(document).on('click', '.cart-text', function () {
      // console.log('inside click cart-text');
      var sku = $(this).closest('.accessory-button').attr('id').split('_')[1];

      if (sku && !isNaN(sku)) {
        $.get('https://' + apiPath + '.melaleuca.com/search/v3', {
          id: 'products_by_sku',
          index: locale,
          params: JSON.stringify({
            skus: [sku.toString()],
            size: 1000
          })
        }).error(function (err) {
          return 'Sku ' + sku + ' not found. | ' + err.responseText;
        }).done(function (data) {
          var prodSku;
          var prodName;
          var prodPricePref;
          var prodPriceReg; // console.log('inside click cart-text');
          //var prodPriceVip;

          if (data.hits.hits.length > 0) {
            prodSku = data.hits.hits[0]._source.sku;
            prodName = data.hits.hits[0]._source.name.enhanced.replace(/<[^>]*>?/gm, '');
            prodPriceReg = data.hits.hits[0]._source.price.retail;
            prodPricePref = data.hits.hits[0]._source.price.preferred;
            prodPriceVip = data.hits.hits[0]._source.price.vip;
            var price = prodPriceReg;

            if (custStatus === 'preferred' || custStatus === 'vip') {
              price = prodPricePref;
            }

            price = currency(price); // per item
            // console.log('above addtocart layer');

            dataLayer.push({
              event: 'addToCart',
              ecommerce: {
                currencyCode: currencyCode,
                add: {
                  products: [{
                    id: prodSku,
                    name: prodName,
                    price: price.toString(),
                    brand: '',
                    category: '',
                    variant: '',
                    quantity: 1
                  }]
                }
              }
            });
          }
        });
      }
    }); // Record when item(s) are removed from the cart:

    $(document).on('click', '.remove-button', function () {
      var item = new Array();
      var thisRow = $(this).closest('.cartProdRow ');
      item['Quantity'] = thisRow.find('input.cartProdQty').val();

      if (!item['Quantity']) {
        item['Quantity'] = thisRow.find('input.cartProdQty').attr('value');
      }

      item['tier1Price'] = thisRow.find('.cartProdRegPrice').text().trim();
      item['tier2Price'] = thisRow.find('.cartProdRowPrice ').text().trim();
      item['SKU'] = thisRow.find('.sku-number').text().trim();
      item['DescriptionNoHTML'] = thisRow.find('.clsProdName').text().trim();
      var price = item['tier1Price'];

      if (custStatus == 'preferred') {
        price = item['tier2Price'];
      }

      price = currency(price) / parseInt(item.Quantity); // per item

      dataLayer.push({
        event: 'removeFromCart',
        ecommerce: {
          currencyCode: currencyCode,
          remove: {
            products: [{
              id: item.SKU,
              name: item.DescriptionNoHTML,
              price: price.toString(),
              brand: '',
              category: '',
              variant: '',
              quantity: parseInt(item.Quantity)
            }]
          }
        }
      });
    }); // Record when quantities change (either add or remove item)

    var initialQuantity = new Array();
    var curQuantity = new Array();
    var quantityDiff = 0;
    $('body').on('focus focusin mousedown', 'input.cartProdQty', function (e) {
      var sku = e.target.id.split('_')[1];
      initialQuantity[sku] = $(this).val();
    });
    $('body').on('blur focusout', 'input.cartProdQty', function (e) {
      var sku = e.target.id.split('_')[1];
      curQuantity[sku] = $(this).val();
      var name = $(this).closest('.cartProdRow').find('.clsProdName').text().trim();
      var price = 0;

      if (custStatus == 'preferred' || custStatus == 'vip') {
        price = parseFloat(currency($('#cartTier2PriceType' + sku).text()));
      } else {
        price = parseFloat(currency($('#cartTier1PriceType' + sku).text()));
      }

      price = price / initialQuantity[sku];

      if (curQuantity[sku] < initialQuantity[sku]) {
        quantityDiff = initialQuantity[sku] - curQuantity[sku];
        initialQuantity[sku] = curQuantity[sku];
        dataLayer.push({
          event: 'addToCart',
          ecommerce: {
            currencyCode: currencyCode,
            remove: {
              products: [{
                id: sku,
                name: name,
                price: price.toString(),
                brand: '',
                category: '',
                variant: '',
                quantity: quantityDiff
              }]
            }
          }
        });
      } else if (curQuantity[sku] > initialQuantity[sku]) {
        quantityDiff = curQuantity[sku] - initialQuantity[sku];
        initialQuantity[sku] = curQuantity[sku];
        dataLayer.push({
          event: 'addToCart',
          ecommerce: {
            currencyCode: currencyCode,
            add: {
              products: [{
                id: sku,
                name: name,
                price: price.toString(),
                brand: '',
                category: '',
                variant: '',
                quantity: quantityDiff
              }]
            }
          }
        });
      }
    });
    /**************************
     * Promo/Banner Views
     * ***********************/

    if ($('[data-aid]').length > 0) {
      var promos = [],
          promoObj = [],
          promoAll = [],
          promoName = '',
          promoUrl = '';
      $('[data-aid]').each(function (i) {
        promoUrl = $(this).attr('href');

        if ($(this).data('aid')) {
          var thisId = null;

          if ($(this).attr('id')) {
            thisId = $(this).attr('id');
          } else {
            thisId = 'tempID' + i;
            $(this).attr('id', thisId);
          }

          promoAll = $(this).data('aid').split('|');
          promoName = $(this).data('aname') ? $(this).data('aname') : '';
          var promoId = $(this).data('aid'),
              promoCreative = promoAll[5] && promoAll[5] !== '@' ? promoAll[5] : '',
              promoPosition = promoAll[2] && promoAll[2] !== '@' ? promoAll[2] : '';
          promoObj[promoId] = {
            id: promoId,
            name: promoName,
            creative: promoCreative,
            position: promoPosition.toString()
          };

          if (isVisibleSimple(document.getElementById(thisId))) {
            promos.push({
              id: promoId,
              name: promoName,
              creative: promoCreative,
              position: promoPosition.toString()
            });
          }

          $(this).on('click', function (e) {
            if (promoUrl !== '') {
              e.preventDefault();
              promotionClick(promoObj[$(this).data('aid')], $(this).attr('href'), $(this).attr('target'));
            }
          });

          if (thisId.indexOf('tempID') >= 0) {
            thisId = null;
          }

          $(this).attr('id', thisId);
        }
      });
      promotionView(promos);
    }
    /**** Detect AJAX changes on detail pages: ****/


    var moveon = 0,
        currSku = parseInt($('#lblSku').text());

    function setupPg(sku) {
      if (typeof productDetail != 'undefined') {
        if (typeof productDetail.Product != 'undefined') {
          // Setup page for each sku, remember to provide a default in case a new sku is added to family
          //console.log('Detected ajax changes');
          ga_detailpage();
        }
      }
    }

    setupPg(); // listener for side menu, promotional or suggested purchases

    $('body').on('click', '.recItem, #pdp_nextSubCatHover, #pdp_prevSubCatHover', function (e) {
      waitForSkuUpdate();
    }); // listener for dropdown change at the top of a detail page

    $('body').on('change', '#optionsList, #optionsList select', function () {
      waitForSkuUpdate();
    });

    function waitForSkuUpdate() {
      setTimeout(function () {
        moveon += 1;

        if (currSku !== parseInt($('#lblSku').text()) || moveon >= 150) {
          currSku = parseInt($('#lblSku').text());
          setupPg();
          moveon = 0;
          return -1;
        } else {
          waitForSkuUpdate();
        }
      }, 200);
    }
  });

}());
